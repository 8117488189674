<template>
  <VApp
    :class="{'guest-page': isGuestPage}"
    :style="styles"
  >
    <Component :is="layout">
      <ErrorBoundary>
        <RouterView />
      </ErrorBoundary>
    </Component>
    <UNotification />
  </VApp>
</template>

<script>
import { mapGetters } from 'vuex'

import GuestLayout from '@/layouts/GuestLayout.vue'
import MainLayout from '@/layouts/MainLayout.vue'
import EmptyLayout from '@/layouts/EmptyLayout.vue'
import UNotification from '@components/UNotification.vue'
import * as getters from '@/store/getters/types'

export default {
  name: 'App',
  components: {
    UNotification,
    GuestLayout,
    MainLayout,
    EmptyLayout,
    ErrorBoundary: () => import(/* webpackChunkName: "error-boundary" */ '@components/ErrorBoundary.vue')
  },

  computed: {
    ...mapGetters({
      vendor: getters.VENDOR
    }),

    layoutTitle () {
      if (typeof this.$route.meta.layout === 'function') {
        return this.$route.meta.layout()
      } else {
        return this.$route.meta.layout
      }
    },

    layout () {
      return `${this.layoutTitle || 'Empty'}Layout`
    },

    isGuestPage () {
      return this.layoutTitle === 'Guest'
    },

    styles () {
      return {
        '--app-bar-height': `${this.$vuetify?.application?.top}px`,
        '--app-navigation-width': `${this.$vuetify?.application?.left}px`,
      }
    }
  }
}
</script>

<style lang="scss">
@import './assets/fonts/material-icons/material-icons.css';
@import './assets/fonts/SourceSansPro/index.css';
@import './styles/main.scss';

.v-application.v-application.guest-page {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
