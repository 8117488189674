<template>
  <VMain>
    <slot />
  </VMain>
</template>

<script>
export default {
  name: 'ULayoutEmpty'
}
</script>
