import videoFileIcon from '../assets/images/video-file-icon.svg'
import audioFileIcon from '../assets/images/audio-file-icon.svg'
import unknownFileIcon from '../assets/images/unknown-file-icon.svg'
import textFileIcon from '../assets/images/text-file-icon.svg'

const read = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = () => {
      reader.abort()
      reject(reader.error)
    }

    reader.readAsDataURL(file)
  })
}

const setIcon = (file) => {
  if (file.type.startsWith('image')) {
    return read(file)
  }

  if (file.type.startsWith('video')) {
    return videoFileIcon
  }

  if (file.type.startsWith('audio')) {
    return audioFileIcon
  }

  if (file.type.startsWith('text')) {
    return textFileIcon
  }

  return unknownFileIcon
}

export default {
  bind: function (el, binding, vnode) {
    if (el instanceof HTMLInputElement && el.type === 'file') {
      el.addEventListener('change', async () => {
        const files = await Promise.all(Array.from(el.files).map(async file => {
          file.icon = await setIcon(file)
          return file
        }))

        if (el.multiple) {
          binding.value.push(...files)
        } else {
          if (binding.value instanceof Array) {
            binding.value.splice(0, binding.value.length, ...files)
          } else {
            vnode.context[binding.expression] = files[0]
          }
        }

        el.value = ''
      })
    }

    if (binding.arg && binding.arg === 'remove') {
      el.addEventListener('click', () => {
        if (binding.value.files && binding.value.idx !== undefined) {
          binding.value.files.splice(binding.value.idx, 1)
        }
      })
    }
  }
}
