<template>
  <div class="notifications">
    <VAlert
      v-for="notification, idx in notifications"
      :key="idx"
      class="notification"
      transition="scale-transition"
      :type="notification.type"
    >
      {{ notification.msg }}

      <template #close>
        <VBtn
          icon
          small
          :aria-label="$t('$vuetify.close')"
          @click="deleteNotification(notification)"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </template>
    </VAlert>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

export default {
  name: 'UNotification',

  computed: mapGetters({
    notifications: getters.NOTIFICATIONS
  }),

  methods: {
    ...mapActions({
      deleteNotification: actions.DELETE_NOTIFICATION
    })
  }
}
</script>

<style lang="scss">
.v-application {
  .notifications {
    position: fixed;
    top: var(--app-bar-height);
    right: 0;
    z-index: 999;
  }

  .notification {
    max-width: 400px;
    min-width: 320px;
    margin-bottom: 0.5rem;
  }
}
</style>
