export default {
  data () {
    return {
      documentTitle: '$app.loading'
    }
  },

  computed: {
    localDocumentTitle () {
      return this.$t(this.documentTitle)
    }
  },

  watch: {
    localDocumentTitle: {
      handler: function (val) {
        document.title = val
      },
      immediate: true
    }
  }
}
