<template>
  <VApp>
    <VDialog
      :value="true"
      width="800"
      persistent
    >
      <VCard>
        <VCardTitle class="headline">
          Ваш браузер устарел!
        </VCardTitle>
        <VDivider />
        <VCardText class="text--primary mt-4">
          Вы&nbsp;используете устаревшую версию браузера, в&nbsp;которой сайт частично или полностью теряет свою функциональность.
          Зайдите на&nbsp;сайт через&nbsp;более свежий браузер.
        </VCardText>

        <VCardText class="text--primary">
          <VRow>
            <VCol>
              <h2 class="headline">
                Настольные браузеры
              </h2>
              <ul>
                <li>
                  Браузеры на&nbsp;основе <a
                    href="https://www.chromium.org/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Chromium</a> версии 50 и&nbsp;выше:
                  <ul>
                    <li>
                      <a
                        href="https://www.google.com/intl/ru/chrome/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Google Chrome</a> версии 50 и&nbsp;выше;
                    </li>
                    <li>
                      <a
                        href="https://brave.com/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Brave</a> любой версии;
                    </li>
                    <li>
                      <a
                        href="https://www.opera.com/ru/browsers/opera"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Opera</a> версии 37 и&nbsp;выше;
                    </li>
                    <li>
                      <a
                        href="https://vivaldi.com/ru/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Vivaldi</a> версии 1.3 и&nbsp;выше;
                    </li>
                    <li>
                      <a
                        href="https://browser.yandex.ru/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Яндекс.Браузер</a> версии 17 и&nbsp;выше;
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="https://www.microsoft.com/ru-ru/edge"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Microsoft Edge</a> версии 16 и&nbsp;выше;
                </li>
                <li>
                  <a
                    href="https://www.mozilla.org/ru/firefox/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Mozilla Firefox</a> версии 60 и&nbsp;выше;
                </li>
                <li>
                  <a
                    href="https://support.apple.com/ru-ru/safari"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Safari</a> версии 11 и&nbsp;выше (Mac OS X 10.11 El Capitan и&nbsp;выше).
                </li>
              </ul>
            </VCol>

            <VCol>
              <h2 class="headline">
                Мобильные браузеры
              </h2>
              <ul>
                <li>
                  Браузеры на&nbsp;основе <a
                    href="https://www.chromium.org/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Chromium</a> версии 50 и&nbsp;выше:
                  <ul>
                    <li>
                      <a
                        href="https://www.google.com/intl/ru/chrome/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Google Chrome</a> версии 50 и&nbsp;выше;
                    </li><li>
                      <a
                        href="https://brave.com/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Brave</a> любой версии;
                    </li>
                    <li>
                      <a
                        href="https://www.opera.com/ru/browsers/opera"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Opera</a> версии 37 и&nbsp;выше;
                    </li>
                    <li>
                      <a
                        href="https://vivaldi.com/ru/android/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Vivaldi</a> версии 1.3 и&nbsp;выше;
                    </li>
                    <li>
                      <a
                        href="https://browser.yandex.ru/mobile/"
                        target="_blank"
                        rel="noopener nofollow noreferrer"
                      >Яндекс.Браузер</a> версии 17 и&nbsp;выше;
                    </li>
                  </ul>
                </li>
                <li>
                  <a
                    href="https://www.microsoft.com/ru-ru/edge"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Microsoft Edge</a> версии 16 и&nbsp;выше;
                </li>
                <li>
                  <a
                    href="https://www.mozilla.org/ru/firefox/browsers/mobile/"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Mozilla Firefox</a> версии 60 и&nbsp;выше;
                </li>
                <li>
                  <a
                    href="https://support.apple.com/ru-ru/safari"
                    target="_blank"
                    rel="noopener nofollow noreferrer"
                  >Safari</a> версии 11 и&nbsp;выше (iOS 11 и&nbsp;выше).
                </li>
              </ul>
            </VCol>
          </VRow>
        </VCardText>
      </VCard>
    </VDialog>
  </VApp>
</template>

<script>
import documentTitle from '../mixins/documentTitle'

export default {
  name: 'UBrowserSupport',

  mixins: [
    documentTitle
  ],

  data () {
    return {
      documentTitle: 'Ваш браузер устарел!'
    }
  }
}
</script>
