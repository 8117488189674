import { getNewMessagesCount } from '@/api/api'
import store from '../store/store'
import * as actions from '../store/actions/types'

class NewMessagesCounter {
  #cnt = 0
  #key = 'new-messages-count'
  #timeOut = null

  get value () {
    return this.#cnt || 0
  }

  async refresh () {
    await this.update()
    // Через [45..75] секунд повторить.
    this.#timeOut = setTimeout(this.refresh.bind(this), (45 + 30 * Math.random()) * 1000)
  }

  async update () {
    const { count: newMessagesCount } = await getNewMessagesCount()
    this.#cnt = parseInt(newMessagesCount, 10) || 0

    // Запомним текущее значение счётчика.
    if ('sessionStorage' in window) {
      sessionStorage.setItem(this.#key, this.value)
    }

    store.dispatch(actions.UPDATE_COUNT_NEW_MESSAGES, this.value)

    return this.value
  }

  start () {
    if ('sessionStorage' in window) {
      this.#cnt = parseInt(sessionStorage.getItem(this.#key), 10) || 0
    }

    store.dispatch(actions.UPDATE_COUNT_NEW_MESSAGES, this.value)

    // Через [2..6] секунды начнём периодический опрос HTTP-API.
    setTimeout(this.refresh.bind(this), (2 + 4 * Math.random()) * 1000)
  }

  stop () {
    clearTimeout(this.#timeOut)
  }
}

export const newMessagesCounter = new NewMessagesCounter()
