export default class Notification {
  #timer = null
  #duration = 10000

  constructor (notification, cb) {
    this.msg = notification.msg
    this.type = notification.type || 'primary'

    this.#timer = setTimeout(cb(notification), this.#duration)
  }

  destroy () {
    if (this.#timer) {
      clearTimeout(this.#timer)
      this.#timer = null
    }
  }
}
