import colors from 'vuetify/lib/util/colors'

interface ITheme {
  title: string,
  localizedTitle: string,
  background: string,
  text: string
}
interface IThemes {
  [key: string]: ITheme
}

export const BASE_THEME = {
  primary: colors.blue.base,
  success: colors.green.base,
  accent: colors.pink.base,
  warning: colors.orange.base,
  error: colors.red.base,
  background: colors.lightBlue.darken1,
  text: '#fff'
}

export const THEMES: IThemes = {
  black: {
    title: 'black',
    localizedTitle: 'Черная',
    background: colors.grey.darken4,
    text: '#fff'
  },
  grey: {
    title: 'grey',
    localizedTitle: 'Серая',
    background: colors.grey.darken2,
    text: '#fff'
  },
  'blue-grey': {
    title: 'blue-grey',
    localizedTitle: 'Серо-голубая',
    background: colors.blueGrey.darken2,
    text: '#fff'
  },
  white: {
    title: 'white',
    localizedTitle: 'Белая',
    background: 'transparent',
    text: '#000'
  },
  'dark-red': {
    title: 'dark-red',
    localizedTitle: 'Серо-красная',
    background: colors.red.darken2,
    text: '#fff'
  },
  'dark-pink': {
    title: 'dark-pink',
    localizedTitle: 'Серо-розовая',
    background: colors.pink.accent4,
    text: '#fff'
  },
  'dark-yellow': {
    title: 'dark-yellow',
    localizedTitle: 'Серо-жёлтая',
    background: colors.yellow.darken2,
    text: '#000'
  },
  'dark-orange': {
    title: 'dark-orange',
    localizedTitle: 'Серо-оранжевая',
    background: colors.orange.darken2,
    text: '#000'
  },
  red: {
    title: 'red',
    localizedTitle: 'Красная',
    background: colors.red.darken3,
    text: '#fff'
  },
  amaranth: {
    title: 'amaranth',
    localizedTitle: 'Амарантовая',
    background: colors.red.accent3,
    text: '#fff'
  },

  'light-amaranth': {
    title: 'light-amaranth',
    localizedTitle: 'Светло-амарантовая',
    background: colors.red.accent3,
    text: '#fff'
  },

  'dark-amaranth': {
    title: 'dark-amaranth',
    localizedTitle: 'Серо-амарантовая',
    background: colors.red.accent3,
    text: '#fff'
  },

  pink: {
    title: 'pink',
    localizedTitle: 'Розовая',
    background: colors.pink.darken3,
    text: '#fff'
  },
  purple: {
    title: 'purple',
    localizedTitle: 'Фиолетовая',
    background: colors.purple.darken3,
    text: '#fff'
  },
  'deep-purple': {
    title: 'deep-purple',
    localizedTitle: 'Тёмно-фиолетовая',
    background: colors.deepPurple.darken3,
    text: '#fff'
  },
  indigo: {
    title: 'indigo',
    localizedTitle: 'Индиго',
    background: colors.indigo.darken3,
    text: '#fff'
  },
  blue: {
    title: 'blue',
    localizedTitle: 'Синяя',
    background: colors.blue.darken3,
    text: '#fff'
  },
  'light-blue': {
    title: 'light-blue',
    localizedTitle: 'Светло-синяя',
    background: colors.lightBlue.darken3,
    text: '#fff'
  },
  'dark-blue': {
    title: 'dark-blue',
    localizedTitle: 'Серо-синяя',
    background: colors.blue.darken2,
    text: '#fff'
  },
  cyan: {
    title: 'cyan',
    localizedTitle: 'Голубая',
    background: colors.cyan.darken3,
    text: '#fff'
  },
  teal: {
    title: 'teal',
    localizedTitle: 'Бирюзовая',
    background: colors.teal.darken3,
    text: '#fff'
  },
  green: {
    title: 'green',
    localizedTitle: 'Зелёная',
    background: colors.green.darken3,
    text: '#fff'
  },
  'light-green': {
    title: 'light-green',
    localizedTitle: 'Светло-зелёная',
    background: colors.lightGreen.darken3,
    text: '#fff'
  },
  'dark-green': {
    title: 'dark-green',
    localizedTitle: 'Серо-зелёная',
    background: colors.green.darken2,
    text: '#fff'
  },
  lime: {
    title: 'lime',
    localizedTitle: 'Лайм',
    background: colors.lime.darken3,
    text: '#000'
  },
  yellow: {
    title: 'yellow',
    localizedTitle: 'Жёлтая',
    background: colors.yellow.darken3,
    text: '#000'
  },
  amber: {
    title: 'amber',
    localizedTitle: 'Золотисто-оранжевая',
    background: colors.amber.darken3,
    text: '#000'
  },
  orange: {
    title: 'orange',
    localizedTitle: 'Оранжевая',
    background: colors.orange.darken3,
    text: '#000'
  },
  'deep-orange': {
    title: 'deep-orange',
    localizedTitle: 'Тёмно-оранжевая',
    background: colors.deepOrange.darken3,
    text: '#fff'
  },
  brown: {
    title: 'brown',
    localizedTitle: 'Коричневая',
    background: colors.brown.darken3,
    text: '#fff'
  }
}
