<template>
  <VDialog
    v-model="dialog"
    scrollable
    content-class="fill-height"
    max-width="960"
  >
    <template #activator="{ on, attrs }">
      <VBtn
        icon
        :title="$t('$app.page_help')"
        v-bind="attrs"
        v-on="on"
      >
        <VIcon>help_outline</VIcon>
      </VBtn>
    </template>
    <VCard
      class="context-help"
      :loading="loading"
    >
      <VToolbar
        class="flex-grow-0"
        flat
      >
        <VBtn
          v-if="!isContents"
          title="Перейти к содержанию справочного центра"
          icon
          @click="() => onChangeURI()"
        >
          <VIcon>arrow_back</VIcon>
        </VBtn>
        <VToolbarTitle>{{ $t('help_center.one') }}</VToolbarTitle>
        <VSpacer />
        <VBtn
          :title="$t('close.one')"
          icon
          @click="dialog=false"
        >
          <VIcon>close</VIcon>
        </VBtn>
      </VToolbar>
      <VDivider />
      <VCardText class="text--primary pt-4">
        <div v-if="loading">
          {{ $t('loading_please_wait.one') }}...
        </div>
        <div v-else-if="error">
          {{ $t('help_not_found.one') }}...
        </div>
        <div
          v-else
          class="rte-content"
          @click="onClick"
          v-html="body"
        />
      </VCardText>
      <VDivider />
      <VCardActions>
        <VBtn
          text
          :href="href"
          target="_blank"
          rel="noopener noreferrer"
        >
          Руководство пользователя
          <VIcon right>
            open_in_new
          </VIcon>
        </VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
</template>

<script>
import { mapGetters } from 'vuex'

import * as getters from '@/store/getters/types'

function getURISuffix (userRole = 'guest', userRolePlural = 'guests', lessonType = '') {
  const uriChunks = document.location.pathname
    .split('/')
    .filter((s) => (s !== '') && (s !== process.env.VUE_APP_PUBLIC_PATH))

  switch (uriChunks[0]) {
    case 'users': {
      return `users/${userRolePlural}/${userRole}/index.html`
    }

    case 'sessions': {
      if (uriChunks.length < 2) {
        return `${uriChunks[0]}/index.html`
      }

      if (lessonType) {
        return `${uriChunks[0]}/${uriChunks[0].substr(0, uriChunks[0].length - 1)}/${lessonType}/index.html`
      }

      return `${uriChunks[0]}/${uriChunks[0].substr(0, uriChunks[0].length - 1)}/index.html`
    }

    default: {
      if (uriChunks.length < 2) {
        return `${uriChunks[0]}/index.html`
      }

      if (uriChunks[1] === 'import' || uriChunks[1] === 'export' || uriChunks[1] === 'clone') {
        return `${uriChunks[0]}/${uriChunks[1]}.html`
      }

      return `${uriChunks[0]}/${uriChunks[0].substr(0, uriChunks[0].length - 1)}/index.html`
    }
  }
}

export default {
  name: 'ContextHelp',

  data () {
    return {
      dialog: false,
      loading: true,
      body: '',
      error: '',
      isContents: false,
      baseURISuffix: 'index.html'
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      vendor: getters.VENDOR,
      lesson: getters.LESSON
    }),

    href () {
      const defaultHref = 'https://uchi.pro/support/manuals'
      const listenersManual = this.vendor?.branding?.listeners_manual

      return listenersManual ? `/${listenersManual}` : defaultHref
    },

    baseURI () {
      const lang = localStorage.getItem('lang') || 'ru'
      return `/help/${lang}/listener-new-ui/`
    }
  },

  watch: {
    dialog: {
      handler: async function (val) {
        if (!val) {
          this.body = ''
          return
        }

        const URI = this.baseURI + getURISuffix(this.account.role.code, this.account.role.code_plural, this.lesson?.type_info.code)
        this.onChangeURI(URI)
      }
    }
  },

  methods: {
    onClick (evt) {
      const helpLink = evt.target.closest('a.help-link')
      if (helpLink) {
        evt.preventDefault()
        this.onChangeURI(helpLink.href)
      }
    },

    async onChangeURI (URI = this.baseURI + this.baseURISuffix) {
      this.loading = true
      try {
        const response = await fetch(`${URI}?_t=${new Date().valueOf()}`)
        this.body = await response.text()

        if (URI === this.baseURI + this.baseURISuffix) {
          this.isContents = true
        } else {
          this.isContents = false
        }
      } catch (e) {
        this.error = e.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
